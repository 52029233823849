




















import { debounceProcess } from "@/helpers/debounce";
import { usePurchaseOrder } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PurchaseOrderResponseDto } from "@/models/interface/purchase-order";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import Select from "./Select.vue";

@Component({
  components: {
    Select,
  },
})
export default class SelectPurchaseOrder extends Vue {
  @Prop({
    required: false,
    type: Object as () => LabelInValue,
    default: undefined,
  })
  value!: LabelInValue | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly filtered!: boolean;

  options: Array<Option<PurchaseOrderResponseDto>> = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);
    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    params.sorts = "date:desc";
    const { findAllNew, toOptionsNew } = usePurchaseOrder();
    this.loading = true;
    findAllNew(params)
      .then(({ data }) => {
        if (this.filtered) {
          const options = data.filter(item => Boolean(item.documentNumber));
          this.options = toOptionsNew(options);
        } else {
          this.options = toOptionsNew(data);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e?: LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val?: string): void {
    const { searchBy } = usePurchaseOrder();
    const params = new RequestQueryParams();
    params.search = searchBy({ docNumber: val });

    this.fetchOptions(params);
  }

  findOption(
    value?: LabelInValue
  ): Option<PurchaseOrderResponseDto> | undefined {
    if (value) {
      return this.options.find(e => value.key === e.value);
    }

    return undefined;
  }
}
